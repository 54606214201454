import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useServerStatus from "../../hooks/useServerStatus";
import { isMobile } from "../../scripts/common";
import Breadcrumbs from "./Breadcrumbs";
import { getHubTableProps } from "./modules/hub/hubTableProps";
import InstallationTabSelector from "./InstallationTabSelector";
import { getProcessorTableProps } from "./modules/processor/processorTableProps";
import { getSensorTableProps } from "./modules/sensor/sensorTableProps";
import { getSourceTableProps } from "./modules/source/sourceTableProps";
import { getSourceSelectorTableProps } from "./modules/sourceSelector/sourceSelectorTableProps";
import EditableTableContainer from "./table/EditableTableContainer";
import { getSpeakerTableProps } from "./modules/speaker/speakerTableProps";
import { displayModes } from "./utils/installationTypes";
import { getZoneTableProps } from "./modules/zone/zoneTableProps";
import { getSourceMatrixTableProps } from "./modules/sourceMatrix/sourceMatrixTableProps";
import theme from "../../UI/theme";
import InstallationDocumentationView from "./modules/installationDocumentation/InstallationDocumentationView";
import VenueName from "./VenueName";
import { getMeterTableProps } from "./modules/meter/meterTableProps";
import { getHeatmapData } from "../../scripts/heatmap";
import InstallationCalibration from "./modules/installationCalibration/InstallationCalibration";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fill-available;
    padding: 10px;

    --marginBottom: 0px;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        --marginBottom: 40px;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        --marginBottom: 126px;
    }

    margin-bottom: var(--marginBottom);
`

const CUSTOMIZED_TABS = [
    'DOCUMENTATION',
    'CALIBRATION',
]

const SettingsContainer = ({
    customerId,
    hubs,
    processors,
    sources,
    sourceSelectors,
    zones,
    sensors,
    sourceMatrixes,
    meters,
    speakers,
    selectedTab,
    setSelectedTab,
    selectedItem,
    setSelectedItem,
    selectedItemHierarchy,
    setSelectedItemHierarchy
}) => {
    const [displayMode, setDisplayMode] = useState(isMobile() ? displayModes.LIST : displayModes.TABLE);
    const [heatmapFloors, setHeatmapFloors] = useState([]);
    const editableTableRef = useRef();
    const serverStatus = useServerStatus();
    const [showInfoHeader, setShowInfoHeader] = useState(false);
    const [showActionPrompts, setShowActionPrompts] = useState(false);
    const [zigbeeNetworkOpenTimeState, setZigbeeNetworkOpenTimeState] = useState({min: 3, sec: 0});
    const timerRef = useRef(null); // Ref to store the interval ID

    const startZigbeeOpenNetworkTimer = (min = 3, sec = 0) => {
        setShowInfoHeaderHandler(true);
    
        // Clear any existing interval to prevent multiple intervals
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    
        // Set initial time state
        setZigbeeNetworkOpenTimeState({ min: min, sec: sec });
    
        // Start the interval
        timerRef.current = setInterval(() => {
            setZigbeeNetworkOpenTimeState(prevState => {
                const { min, sec } = prevState;
                if (min === 0 && sec === 0) {
                    // Time's up, clear interval and hide header
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    setShowInfoHeaderHandler(false);
                    return { min: 0, sec: 0 }; // Final state
                } else if (sec === 0) {
                    // Decrease minute and reset seconds to 59
                    return { min: min - 1, sec: 59 };
                } else {
                    // Decrease seconds
                    return { min, sec: sec - 1 };
                }
            });
        }, 1000);
    };
    
    // Clean up interval on unmount
    useEffect(() => () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
    }, []);

    useEffect(() => { getHeatmapFlors() }, [selectedTab]);

    const getHeatmapFlors = async () => {
        var res = await getHeatmapData(customerId);
        setHeatmapFloors(res.heatmapFloors || []);
    }

    const setShowInfoHeaderHandler = (state, durationInSeconds=false) => {
        setShowInfoHeader(state);
        if (durationInSeconds) {
            setTimeout(() => {
                setShowInfoHeader(false);
            }, durationInSeconds * 1000);
        }
    }

    const setShowActionPromptsHandler = (state, durationInSeconds=false) => {
        setShowActionPrompts(state);
        if (durationInSeconds) {
            setTimeout(() => {
                setShowActionPrompts(false);
            }, durationInSeconds * 1000);
        }
    }

    const getTableProps = () => {
        switch (selectedTab.key) {
            case 'HUB':
                return getHubTableProps({ hubs, processors, sensors, customerId, serverStatus, displayMode })

            case 'PROCESSOR':
                return getProcessorTableProps({ processors, hubs, customerId, serverStatus, displayMode })

            case 'SOURCE':
                return getSourceTableProps({ sources, processors, customerId })

            case 'SOURCE_MATRIX':
                return getSourceMatrixTableProps({ sourceMatrixes, processors, sources, customerId, setSelectedTab })

            case 'SOURCE_SELECTOR':
                return getSourceSelectorTableProps({ sourceSelectors, processors, sources, sourceMatrixes, customerId, setSelectedTab })

            case 'ZONE':
                return getZoneTableProps({ customerId, zones, processors, sourceSelectors, meters, hubs, setSelectedTab })

            case 'SPEAKER':
                return getSpeakerTableProps({ customerId, hubs, processors, zones, speakers, setSelectedTab, setShowActionPromptsHandler });

            case 'METER':
                return getMeterTableProps({ customerId, meters, processors })

            case 'SENSOR':
                return getSensorTableProps({ sensors, zones, hubs, customerId, serverStatus, displayMode, showInfoHeader, setShowInfoHeaderHandler, zigbeeNetworkOpenTimeState, startZigbeeOpenNetworkTimer })
            default:
                return <></>
        }
    }

    return <Container>
        <VenueName />
        <Breadcrumbs
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedItemHierarchy={selectedItemHierarchy}
            setSelectedItemHierarchy={setSelectedItemHierarchy}
        />

        <InstallationTabSelector
            selectedTab={selectedTab}
            onClick={(newTab) => setSelectedTab(newTab)}
            editableTableRef={editableTableRef}
            sourceMatrixes={sourceMatrixes}
            meters={meters}
            processors={processors}
            sources={sources}
            sourceSelectors={sourceSelectors}
            zones={zones}
            sensors={sensors}
            speakers={speakers}
            hubs={hubs}
            heatmapFloors={heatmapFloors}
        />

        {!CUSTOMIZED_TABS.includes(selectedTab.key) && 
            <EditableTableContainer
                {...getTableProps()}
                displayMode={displayMode}
                setDisplayMode={setDisplayMode}
                editableTableRef={editableTableRef}
                showInfoHeader={showInfoHeader}
                showActionPrompts={showActionPrompts}
            />
        }   

        { selectedTab.key === 'DOCUMENTATION' &&
            <InstallationDocumentationView
                customerId={customerId}
                hubs={hubs}
                processors={processors}
                sourceSelectors={sourceSelectors}
                zones={zones}
                sensors={sensors}
            />
        }

        { selectedTab.key === 'CALIBRATION' &&
            <InstallationCalibration
                hideHeader={true}
                customerId={customerId}
            />
        }

    </Container>
}

export default SettingsContainer;