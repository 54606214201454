import React, { useState, useRef } from 'react';
import ButtonContainer from '../../UiComponents/containers/ButtonContainer';
import { FormContainer, FormField, FormInput, ValidationError } from './styles/FormStyles';
import Button from '../../UiComponents/Button';
import { cellStates } from './tableTypes';
import { loadingStates } from '../installationTypes';
import NeutralSpinner from '../../UiComponents/Animations/NeutralSpinner';
import { ButtonRowContainer, ModalHeader } from '../../panel/ModalStyles';

const EditableForm = ({ columns, onSubmit, onCancel, initProps, title }) => {
    const [object, setObject] = useState(initProps || getSuggestedValues(columns));
    const [activeFieldIndex, setActiveFieldIndex] = useState(0);
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [showValidation, setShowValidation] = useState(false);
    const submitProps = useRef([]);

    const updateSubmitProps = (updatedProps) => {
        submitProps.current = { ...submitProps.current, ...updatedProps };
    }

    const updateObject = (updatedProps) => {
        setObject({ ...object, ...updatedProps });
    }

    const submitHandler = async () => {
        try {
            const isValid = validateFields();
            if (isValid) {
                const timeout = setTimeout(() => {
                    setLoadingState(loadingStates.LOADING);
                }, 500);

                await onSubmit({ ...object }, submitProps.current);
                clearTimeout(timeout);
                setLoadingState(loadingStates.SUCCESS);
            } else {
                setShowValidation(true);
            }
        } catch (err) {
            console.log('Failed to submit form');
            setLoadingState(loadingStates.FAILED);
        }
    }

    const validateFields = () => {
        return !columns.filter(col => col.form && col.formValidate).some(col => col.formValidate(object)?.type === 'ERROR');
    }

    const onFocus = (ix) => {
        setActiveFieldIndex(ix);
    }

    return <>
        {title ? <>
            <ModalHeader>Add {formatTitle(title)}</ModalHeader>
        </> : <></>}
        {columns.filter(column => column.form).map((column, ix) => {
            const cellState = (ix === activeFieldIndex) ? cellStates.ACTIVE : cellStates.FORM;
            const cellControl = { cellState, updateSubmitProps, isForm: true };
            const hideInForm = column.hideInItem && column.hideInItem(object);
            if (!hideInForm) {
                return <FormField key={ix} onFocus={() => onFocus(ix)}>
                    <div>
                        <label>{column.displayName} {column.formValidate && '*'}</label>
                    </div>
                    <FormInput>
                        {column.render(object, updateObject, cellControl)}
                    </FormInput>
                    {showValidation && column.formValidate ? <>
                        <ValidationError>{column.formValidate(object)?.message}</ValidationError>
                    </> : <></>}
                </FormField>
            } else {
                <></>
            }
        })}
        <div>
            {loadingState !== loadingStates.LOADING ? <>
                <ButtonRowContainer>
                    <Button primary onClick={submitHandler}>Save</Button>
                    <Button secondary onClick={onCancel}>Cancel</Button>
                </ButtonRowContainer>
                {showValidation ? <>
                    <div><ValidationError>Fill out all required fields</ValidationError></div>
                </> : <></>}
            </> : <>
                <NeutralSpinner />
            </>}
        </div>
    </>
}

export default EditableForm;

const getSuggestedValues = (columns) => {
    const suggestedValues = {};
    columns.filter(column => column.form && column.getSuggested).forEach(column => {
        suggestedValues[column.key] = column.getSuggested();
    });
    return suggestedValues;
}

const formatTitle = (title) => {
    if (title.toLowerCase() === 'source matrixes') {
        title = title.slice(0, title.length - 2);
    } else if (title[title.length - 1] === 's') {
        title = title.slice(0, title.length - 1);
    }
    return title.toLowerCase();
}