import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { postEvent } from '../../../../../scripts/event';
import Modal from '../../../../UiComponents/Modal';
import HubChoiceField from '../../../fields/HubChoiceField';
import { cellStates } from '../../../table/tableTypes';
import Button from '../../../../UiComponents/Button';
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText} from '../../../../panel/ModalStyles';

const OpenNetworkModal = ({ hubs, show=false, close=false, startZigbeeOpenNetworkTimer}) => {
    const [showModal, setShowModal] = useState(show);
    const [selectedHub, setSelectedHub] = useState(hubs?.[0]);

    useEffect(() => setShowModal(show) , [show])

    function addSensors() {
        if (selectedHub) {
            postEvent(selectedHub.hubId, selectedHub.customerId, 'ADD_SENSORS');
            startZigbeeOpenNetworkTimer();
            setShowModal(false);
            if (close) close();
        }
    }

    const closeHandler= () => {
        if (setShowModal) {
            setShowModal(false);
            if (close) close();
        }
    }

    return (
        <Modal show={showModal} close={ closeHandler } >
            <ModalContainer>
                <ModalHeader>Enable pairing mode</ModalHeader>
                <ModalText>This action will open the hub for new sensor connections.</ModalText>
                {hubs.length !== 1 ? <>
                    <ModalText>Select the hub you want to enter pairing mode.</ModalText>
                    <HubChoiceField
                        hubId={selectedHub?.hubId}
                        hubs={hubs}
                        updateObject={({ hubId }) => setSelectedHub(hubs.find(hub => hub.hubId === hubId))}
                        cellControl={{ cellState: cellStates.FORM }}
                    />
                </> : <></>}
                <ButtonRowContainer>
                    <Button primary disabled={!selectedHub} onClick={() => addSensors()}>Start pairing mode</Button>
                    <Button secondary onClick={ closeHandler }>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    )
}


export default OpenNetworkModal;