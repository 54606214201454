import React from "react";
import styled from "styled-components";
import CalibrationView from "../../../../views/CalibrationView";
import { Placeholder } from "../../table/styles/TableStyles";
import { getPermissions, getUsers } from "../../../../scripts/permission";
import theme from "../../../../UI/theme";
import Button from "../../../UiComponents/Button";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    h3 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 1.1rem;
    }

    h4 {
        margin: 12px 0px 8px 0px;
        font-size: 0.95rem;
    }
    
    p {
        margin: 0;
        font-size: 0.9rem;
    }
`

const AdministratorContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0px;
    flex-wrap: wrap;
    
`

const Administrator = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    gap: 10px;
    border-right: 1px solid ${theme.colors.darkSpace80};
    border-left: 1px solid ${theme.colors.darkSpace80};
    width: fit-content;
    min-width: 200px;
    margin: 8px 0px;

    p {
        margin: 0;
        font-size: 0.85rem;
    }

`

const InstallationCalibration = ({customerId, hideHeader}) => {

    const [venueAdmins, setVenueAdmins] = React.useState([]);

    React.useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        
        const [allUsers, venuePermissions] = await Promise.all([
        getUsers(),
        getPermissions(customerId, null)
        ]);

        const adminIds = new Set(
        venuePermissions
            .filter(permission => permission.permissionType.includes('userAdministration'))
            .map(permission => permission.userId)
        );

        const installerIds = new Set(
        venuePermissions
            .filter(permission => permission.permissionType.includes('installationView'))
            .map(permission => permission.userId)
        );

        const venueAdministrators = allUsers.filter(user =>
        user.venueIds.includes(customerId.toString()) &&
        adminIds.has(user.userId) &&
        !installerIds.has(user.userId)
        );
        setVenueAdmins(venueAdministrators);
    }


    return (
        <Container>
            <Placeholder>
                <h3>Calibration</h3>
                {venueAdmins.length>1?
                    <p>Calibrate each zone in cooperation with one or more of the Venue Administrators</p>
                :
                    <p>Calibrate each zone in cooperation with the Venue Administrator</p>
                }
                {venueAdmins.length?<>
                    <h4>Venue Administrators:</h4>
                    <AdministratorContainer>
                        {venueAdmins.map(admin => {
                            return (
                                <Administrator key={admin.userId}>
                                    <p>Name: {admin.firstName} {admin.lastName}</p>
                                    <p>Email: {admin.email}</p>
                                </Administrator>
                            )
                        })}
                    </AdministratorContainer>
                </>: <>
                    <p style={{margin: '16px 0px'}}>No Venue Administrators have been added yet.</p>
                    <Button primary small onClick={() => window.open(`/userAdministration?venueId=${customerId}`, "_blank")}>Add Venue Admin</Button>
                </>}
            </Placeholder>
            <CalibrationView
                customerId={customerId}
                hideHeader={hideHeader}
            />
        </Container>
    )
}

export default InstallationCalibration;