import { useEffect, useState } from "react";
import styled from "styled-components";
import { postEvent } from "../../../../../scripts/event";
import Button from "../../../../UiComponents/Button";
import Modal from "../../../../UiComponents/Modal";
import Selector from "../../../../UiComponents/Selector";
import { DisplayField } from "../../../fields/Styles";
import { FieldButton } from "../../../fields/styling/FieldStyles";
import { getHubDisplayName, hasZigbeeNetwork } from "../hubUtils";
import ChannelScan from "./ChannelScan";
import FormNetwork from "./FormNetwork";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../../panel/ModalStyles";

const ZigbeeConfigButton = ({ hub, allHubs }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        {hasZigbeeNetwork(hub) ? <>
            <FieldButton tertiary onClick={() => setShowModal(true)}>Zigbee Settings</FieldButton>
        </> : <>
            <Button small primary onClick={() => setShowModal(true)}>Set up Zigbee</Button>
        </>}

        <Modal show={showModal} close={() => setShowModal(false)} >
            <ModalContainer>
                <ZigbeeConfig hub={hub} allHubs={allHubs} close={() => setShowModal(false)} />
            </ModalContainer>
        </Modal>
    </>
}

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ZigbeeSetupField = ({ hub, allHubs }) => {
    return <>
        <Flex>
            {hasZigbeeNetwork(hub) ? <>
                <DisplayField>
                    Channel {hub.zigbeeChannel}
                </DisplayField>
            </> : <>
            </>}
            <ZigbeeConfigButton hub={hub} allHubs={allHubs} />
        </Flex>
    </>
}

export const SensorZigbeeSetupButton = ({ hubs }) => {
    const [selectedHub, setSelectedHub] = useState(hubs.length === 1 ? hubs[0] : null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        var numNotSetups = hubs.filter(hub => !hasZigbeeNetwork(hub)).length;
        if (numNotSetups === 1) setSelectedHub(hubs.find(hub => !hasZigbeeNetwork(hub)));
    }, [hubs])

    return <>
        <Button primary small onClick={() => setShowModal(true)}>Set up Zigbee</Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                {hubs.filter(hub => !hasZigbeeNetwork(hub))?.length > 1 ? <>
                    <ModalHeader>Select hub for zigbee setup</ModalHeader>
                    <Selector
                        items ={hubs.filter(hub => !hasZigbeeNetwork(hub)).map(hub => ({ display: getHubDisplayName(hub), key: hub.hubId }))}
                        //items={hubs?.map(hub => ({ display: getHubDisplayName(hub), key: hub.hubId }))}
                        selectedItem={{ key: selectedHub?.hubId }}
                        onClick={(item) => setSelectedHub(hubs?.find(hub => hub.hubId === item.key))}
                    />
                </> : <></>}
                {selectedHub ? <>
                    <ZigbeeConfig hub={selectedHub} allHubs={hubs} close={() => setShowModal(false)} />
                </> : <></>}
            </ModalContainer>
        </Modal>
    </>
}

const configStates = {
    NONE: 0,
    CHANNEL_SCAN: 1,
    FORM_NETWORK: 2
}

export const ZigbeeConfig = ({ hub, allHubs, close }) => {
    const [configState, setConfigState] = useState(configStates.FORM_NETWORK); /* CHANNEL SCAN IS DEPRECATED UNTIL FURTHER NOTICE SET configState = configStates.NONE to revert and include channel scan*/
    const [bestChannel, setBestChannel] = useState();
    const [openingNetwork, setOpeningNetwork] = useState(false);

    const openNetwork = async () => {
        try {
            await postEvent(hub.hubId, hub.customerId, 'ADD_SENSORS')
            setOpeningNetwork(true);
            setTimeout(() => {
                setOpeningNetwork(false)
            }, 60000);
        } catch (err) {
            console.log('Failed to open network');
        }
    }

    return <>
        {/* CHANNEL SCAN IS DEPRECATED UNTIL FURTHER NOTICE SET configState = configStates.NONE to revert and include channel scan*/}
        {configState === configStates.NONE ? <>
            <ModalHeader>Zigbee Setup</ModalHeader>
            {hasZigbeeNetwork(hub) ? <>
                <ModalText>Zigbee network set up on channel {hub.zigbeeChannel}</ModalText>
                <ButtonRowContainer>
                    <Button primary disabled={openingNetwork} onClick={() => openNetwork()}>Open network</Button>
                    <Button secondary onClick={() => setConfigState(configStates.CHANNEL_SCAN)}>Channel Scan</Button>
                    <Button secondary onClick={() => setConfigState(configStates.FORM_NETWORK)}>Create zigbee network</Button>
                </ButtonRowContainer>
            </> : <>
                <ModalText>Perform channel scan or create zigbee network on a known channel.</ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={() => setConfigState(configStates.CHANNEL_SCAN)}>Channel Scan</Button>
                    <Button secondary onClick={() => setConfigState(configStates.FORM_NETWORK)}>Create zigbee network</Button>
                </ButtonRowContainer>
            </>}
        </> : <></>}

        {configState === configStates.CHANNEL_SCAN ? <>
            <ChannelScan hub={hub} formNetwork={(bestChannel) => {
                setBestChannel(bestChannel);
                setConfigState(configStates.FORM_NETWORK)
            }} cancel={close} />
        </> : <></>}

        {configState === configStates.FORM_NETWORK ? <>
            <FormNetwork hub={hub} allHubs={allHubs} cancel={close} bestChannel={bestChannel} />
        </> : <></>}
    </>
}

export default ZigbeeConfigButton;