import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { installationTabs } from "../components/installation/installationTypes";
import SettingsContainer from "../components/installation/SettingsContainer";
import SystemTree from "../components/installation/SystemTree";
import { filterItems } from "../components/installation/table/tableUtils";
import { useSubscription } from "../hooks/useSubscription";
import { subscriptionsTypes } from "../scripts/subscription/subscriptionTypes";
import SidePanelContainer from "../components/UiComponents/containers/SidePanelContainer";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const InstallationView = props => {
    const [selectedTab, setSelectedTab] = useState(installationTabs[0]);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedItemHierarchy, setSelectedItemHierarchy] = useState();
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [sensors, setSensors] = useSubscription(subscriptionsTypes.sensors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [sourceMatrixes, setSourceMatrixes] = useSubscription(subscriptionsTypes.sourceMatrixes);
    const [meters, setMeters] = useSubscription(subscriptionsTypes.meters);
    const [speakers, setSpeakers] = useSubscription(subscriptionsTypes.speakers);
    const [filteredItems, setFilteredItems] = useState();

    useEffect(() => {
        checkfQueryVenueId();
    }, [])

    useEffect(() => {
        setSelectedItem(null);
        setSelectedItemHierarchy(null);
    }, [props.customerId])

    useEffect(() => {
        let newFilteredItems = filterItems(hubs, processors, sources, sourceSelectors, zones, sensors, sourceMatrixes, meters, selectedItem);
        setFilteredItems(newFilteredItems);
    }, [hubs, processors, sources, sourceSelectors, zones, sensors, sourceMatrixes, meters, selectedItemHierarchy])

    function checkfQueryVenueId() {
        const urlParams = new URLSearchParams(window.location.search);
        const customerId = Number(urlParams.get('customerId'));
        if (customerId && typeof customerId === 'number') {
            props.setCustomer(customerId);
        }

    }

    if (hubs && processors && sourceSelectors && zones && sensors && sources && sourceMatrixes && meters) {
        return <Container>
            {selectedTab.key !== 'DOCUMENTATION' ? <>
                <SidePanelContainer>
                    <SystemTree
                        hubs={hubs}
                        processors={processors}
                        sourceSelectors={sourceSelectors}
                        zones={zones}
                        sensors={sensors}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        selectedItemHierarchy={selectedItemHierarchy}
                        setSelectedItemHierarchy={setSelectedItemHierarchy}
                    />
                </SidePanelContainer>
            </> : <></>}

            <SettingsContainer
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                selectedItemHierarchy={selectedItemHierarchy}
                setSelectedItemHierarchy={setSelectedItemHierarchy}
                customerId={props.customerId}
                hubs={filteredItems?.hubs ?? hubs}
                processors={filteredItems?.processors ?? processors}
                sources={filteredItems?.sources}
                sourceSelectors={filteredItems?.sourceSelectors ?? sourceSelectors}
                zones={filteredItems?.zones ?? zones}
                sensors={filteredItems?.sensors ?? sensors}
                sourceMatrixes={filteredItems?.sourceMatrixes ?? sourceMatrixes}
                meters={filteredItems?.meters ?? meters}
                speakers={filteredItems?.speakers ?? speakers}
            />
        </Container>
    } else {
        return <></>
    }

}

export default InstallationView;