import { deleteSource, updateSources } from "../../../../scripts/source";
import CheckboxField from "../../fields/CheckBoxField";
import NameField from "../../fields/NameField";
import NumberField from "../../fields/NumberField";
import ProcessorChoiceField from "../../fields/ProcessorChoiceField";
import { DisplayField } from "../../fields/Styles";
import { SourceIcon } from "../../styling/Icons";
import AddObjectButton from "../../table/AddObjectButton";
import { Placeholder } from "../../table/styles/TableStyles";
import { getDefaultId } from "../../table/tableUtils";
import { addNewSource, getNextAddress, saveUpdatedSources } from "./sourceUtils";

const idField = 'sourceId';

export const getSourceTableProps = ({ sources, processors, customerId }) => {

    const getColumns = (sources) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        formValidate: (source) => {
            return source.name ? null : { type: 'ERROR', message: 'Name is required' };
        },
        render: (source, updateSource, cellControl) => (
            <NameField
                object={source}
                updateObject={updateSource}
                objects={sources}
                idField={'sourceId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    },{
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        formValidate: (source) => {
            return source.processorId ? null : { type: 'ERROR', message: 'Processor is required' };
        },
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (source, updateSource, cellControl) => (
            <ProcessorChoiceField
                object={source}
                processors={processors}
                updateObject={updateSource}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'address',
        displayName: 'Input Number',
        width: '100px',
        form: true,
        formValidate: (source) => {
            return source.address ? null : { type: 'ERROR', message: 'Address is required' };
        },
        getSuggested: () => getNextAddress(sources, processors),
        render: (source, updateSource, cellControl) => (
            <NumberField
                value={source.address}
                setValue={(value) => updateSource({ address: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'regulate',
        displayName: 'Automate',
        width: '60px',
        form: true,
        getSuggested: () => { return true },
        render: (source, updateSource, cellControl) => (
            <CheckboxField
                checked={source.regulate}
                setValue={() => updateSource({ regulate: +(!source.regulate) })}
                cellControl={cellControl}
            />
        )
    }];

    const saveObjects = async (changedSources) => {
        try {
            await updateSources(changedSources);
        } catch (err) {
            console.log('Failed to update sources');
        }
    }

    const deleteObject = async (source) => {
        try {
            await deleteSource(source.sourceId);
        } catch (err) {
            console.log('Error failed to delete source');
        }
    }

    const addObject = processors.length ? (newSource) => addNewSource(newSource, processors, customerId) : null;

    const getListItemProps = (source) => {
        return {
            displayName: source.name,
            Icon: SourceIcon
        }
    }
    
    const getPlaceholder = () => {
        return (
            <Placeholder>
                <p>No sources added.</p>
                <AddObjectButton
                    title='source' 
                    objects={sources}
                    onSubmit={addObject}
                    columns={getColumns(sources)}
                    overrideButtonStyle={{
                        title: 'Add source',
                    }}
                />
            </Placeholder>
        )
    }

    const getActions = (source) => [];

    return {
        title: 'Sources',
        getColumns,
        parentObjects: sources,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
