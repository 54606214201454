import { DisplayField } from '../../fields/Styles';
import { getHubDisplayStatus, getHubStatus, getHubStatusColor } from '../../utils/connectionStatus';
import { HubIcon } from '../../styling/Icons';
import { actionTypes } from '../../utils/installationTypes';
import HubAggregatedError, { hasAggregatedError } from './HubAggregatedError';
import StatusField from '../../fields/StatusField';
import { hideInList } from '../../table/tableUtils';
import { getHubDisplayName, hasZigbeeNetwork } from './hubUtils';
import ZigbeeConfigButton, { SensorZigbeeSetupButton, ZigbeeSetupField } from './actions/ZigbeeConfig';
import NetworkConfigField from './fields/NetworkConfigField';
import SystemActionsField from './fields/SystemActionsField';
import NameField from '../../fields/NameField';
import VersionField from './fields/VersionField';
import InstallationInfoButton from '../../fields/components/InstallationInfoButton';
import heatmapTypes from '../../../heatmap/container/heatmapTypes';
import { updateHubs } from '../../../../scripts/hub';
import { Placeholder } from '../../table/styles/TableStyles';

const idField = 'hubId';

export const getHubTableProps = ({ hubs, processors, sensors, customerId, serverStatus, displayMode }) => {

    const getColumns = (hubs) => [{
        displayName: 'Name',
        key: 'name',
        form: true,
        render: (hub, updateHub, cellControl) => (
            <NameField
                object={hub}
                updateObject={updateHub}
                objects={hubs}
                idField={idField}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    },/* {
        displayName: 'Id',
        key: 'hubId',
        render: (hub) => <DisplayField>{hub.hubId}</DisplayField>
    },*/ {
        displayName: 'Status',
        key: 'status',
        hide: hideInList(displayMode),
        render: (hub) => {
            const listItemProps = getListItemProps(hub)
            return <StatusField
                displayStatus={listItemProps.displayStatus}
                statusColor={listItemProps.statusColor}
                error={listItemProps.error}
            />
        }
    }, {
        displayName: 'Connection Type',
        key: 'connectionType',
        render: (hub) => <DisplayField>{hub.connectionType}</DisplayField>
    }, {
        displayName: 'IP Address',
        key: 'ipAddress',
        form: true,
        render: (hub) => (
            <DisplayField>{hub.ipAddress}</DisplayField>
        )
    }, {
        displayName: 'Network Config.',
        key: 'networkConfig',
        form: true,
        render: (hub, updateHub) => (
            <NetworkConfigField
                hub={hub}
                updateHub={updateHub}
                customerId={customerId}
            />
        )
    }, {
        displayName: 'Version',
        key: 'programVersion',
        render: (hub) => <VersionField hub={hub} />
    }, {
        displayName: 'Zigbee Setup',
        key: 'zigbeeConfig',
        render: (hub) => (
            <ZigbeeSetupField hub={hub} allHubs={hubs}/>
        )
    }, {
        displayName: 'System',
        key: 'systemActions',
        render: (hub) => (
            <SystemActionsField hub={hub} />
        )
    }, {
        key: 'installationDocumentation',
        displayName: 'Doc.',
        width: '40px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (hub) => (
            <InstallationInfoButton
                customerId={hub.customerId}
                deviceId={hub.hubId}
                deviceType={heatmapTypes.elementTypes.HUB}
                name={getHubDisplayName(hub)}
            />
        )
    }];

    const getActions = (hub) => [{
        key: 'zigbeeConfig',
        type: actionTypes.PRIMARY,
        hide: hasZigbeeNetwork(hub),
        render: () => (
            <ZigbeeConfigButton hub={hub} />
        )
    }, {
        key: 'installationinfo',
        type: actionTypes.PRIMARY,
        render: () => (
            <InstallationInfoButton
                customerId={hub.customerId}
                deviceId={hub.hubId}
                deviceType={heatmapTypes.elementTypes.HUB}
                name={getHubDisplayName(hub)}
            />
        )
    }]

    const getListItemProps = (hub) => {
        const hubProcessors = processors?.filter(processor => processor.hubId === hub.hubId);
        const hubSensors = sensors?.filter(sensor => sensor.hubId === hub.hubId);
        const status = getHubStatus(hub, serverStatus, hubProcessors, hubSensors);
        return {
            displayName: getHubDisplayName(hub),
            displayStatus: getHubDisplayStatus(status),
            statusColor: getHubStatusColor(status),
            error: hasAggregatedError(status, hubSensors, hubProcessors) && <HubAggregatedError
                hubStatus={status}
                processors={hubProcessors}
                sensors={hubSensors}
            />,
            Icon: HubIcon
        }
    }

    async function saveObjects(changedHubs) {
        try {
            await updateHubs(changedHubs);
        } catch (err) {
            console.log('save error', err);
        }
    }

    const getPlaceholder = () => {
        return <Placeholder>
            <p>No hubs have been added</p>
            </Placeholder>
    }

    const getInfoSection = () => { return null }

    

    return {
        title: 'Hubs',
        getColumns,
        parentObjects: hubs,
        getListItemProps,
        idField,
        saveObjects,
        getActions,
        getPlaceholder,
        getInfoSection,
    }
}