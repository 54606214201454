import React from "react";
import { FaBell } from "react-icons/fa";
import styled from "styled-components";
import theme from "../../UI/theme";

const Marker = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${theme.colors.greenEnergy};
    display: flex;
    justify-content: center;
    align-items: center;
` 

const NotificationMarker = ({show}) => {

    const bellStyle = {
        color: theme.colors.darkSpace,
        fontSize: '0.65rem',
    }

    return show ? <Marker><FaBell style={bellStyle}/></Marker> : null;
}

export default NotificationMarker;