import { useEffect, useState } from "react";
import ChoiceField from "../../../fields/ChoiceField";

const availableZigbeeChannels = [
    { value: 11, label: '11' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
];

const recommendationOrder = [25, 20, 15, 11, 14];

const ZigbeeChannelChoiceField = props => {
    const [selectedItem, setSelectedItem] = useState(availableZigbeeChannels.find(channel => channel.value === props.zigbeeChannel || channel.label.includes('Recommended')));

    useEffect(() => {
        setRecommendedChannel();
    }, [props.hub, props.allHubs]);

    const setRecommendedChannel = () => {
        if (props.hub.zigbeeChannel) {
            concatRecommendedLabel(props.hub.zigbeeChannel);
            return;
        }
        const setupHubs = props.allHubs.filter(hub => hub.zigbeeChannel);
        if (setupHubs.length === 0) concatRecommendedLabel(recommendationOrder[0]);
        else {
            const setupChannels = setupHubs.map(hub => hub.zigbeeChannel);
            const recommendedChannel = recommendationOrder.find(channel => !setupChannels.includes(channel));
            concatRecommendedLabel(recommendedChannel);
        }
    }

    const concatRecommendedLabel = (channelNumber) => {
        availableZigbeeChannels.forEach(channel => channel.label = channel.value.toString());
        const recommendedChannel = availableZigbeeChannels.find(channel => channel.value === channelNumber);
        recommendedChannel.label = `${recommendedChannel.label} (Recommended)`;
        setSelectedItem(recommendedChannel);
    }

    return <ChoiceField 
        selectedItem={selectedItem}
        items={availableZigbeeChannels}
        setValue={props.setValue}
        cellControl={props.cellControl}
        excludeNone={props.excludeNone}
        disabled={props.disabled}
    />
}

export default ZigbeeChannelChoiceField;