import { useState } from "react"
import Button from "../../../../UiComponents/Button"
import Modal from "../../../../UiComponents/Modal";
import ProcessorSetup from "../ProcessorSetup";

const ProcessorScanForm = props => {
    const [expand, setExpand] = useState(false);

    const submitHandler = async (object, submitProps) => {
        await props.onSubmit(object, submitProps);
        setExpand(false);
    }

    return <>
        <Button
            small
            primary = {props.primary}
            secondary = {props.secondary}
            tertiary = {props.tertiary}
            onClick={() => setExpand(!expand)}
        >{expand ? 'Hide scan options' : 'Scan for Processor'}
        </Button>

        {expand && 
            <ProcessorSetup 
                {...props}
                onSubmit={submitHandler}
                cancel={() => setExpand(false)}
                preSelectedProcessor={props.preSelectedProcessor}
            />
        }
    </>
}

export default ProcessorScanForm;