import React, { useState } from 'react';
import { postEvent } from '../../../../../scripts/event';
import Button from '../../../../UiComponents/Button';
import OpenNetworkModal from './OpenNetworkModal';

const OpenNetworkButton = ({ hubs, startZigbeeOpenNetworkTimer }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedHub, setSelectedHub] = useState(hubs?.[0]);

    return <>
        <Button small primary onClick={() => setShowModal(true)}>
            Start pairing mode
        </Button>
        <OpenNetworkModal
            show={showModal}
            close={() => setShowModal(false)}
            hubs={hubs}
            startZigbeeOpenNetworkTimer={startZigbeeOpenNetworkTimer}
        />
    </>
}


export default OpenNetworkButton;