import WavedBox from '../../../../installation/modules/protocol/protocols/WavedBox';
import {AudioSource} from './AudioSource/audioSourceUtils';

const connectionTypes = {
    TCP: 'TCP',
    SERIAL: 'SERIAL'
}

const processorTypes = {
    LONDON: {
        value: 'LONDON',
        label: 'BSS Soundweb London',
        connection: connectionTypes.TCP,
        hasMute: true,
        useMuteAddress: true
    },
    YAMAHA: {
        value: 'YAMAHA',
        label: 'Yamaha',
        connection: connectionTypes.TCP
    },
    BOSE: {
        value: 'BOSE',
        label: 'Bose',
        connection: connectionTypes.TCP
    },
    SONOS: {
        value: 'SONOS',
        label: 'Sonos',
    },
    GENELEC: {
        value: 'GENELEC',
        label: 'Genelec',
    },
    WAVEDBOX: {
        value: 'WAVEDBOX',
        label: 'Waved Box',
        connection: connectionTypes.SERIAL,
        predefinedConfig: WavedBox.getPredefinedConfig()
    },  
    BIAMP: {
        value: 'BIAMP',
        label: 'Biamp Tesira',
        connection: connectionTypes.TCP,
    },
    NEXIA: {
        value: 'NEXIA',
        label: 'Biamp Nexia',
        connection: connectionTypes.TCP,
    },
    XILICA: {
        value: 'XILICA',
        label: 'Xilica',
        connection: connectionTypes.TCP,
    },
    QSYS: {
        value: 'QSYS',
        label: 'QSys',
        connection: connectionTypes.TCP,
    },
    SYMETRIX: {
        value: 'SYMETRIX',
        label: 'Symetrix',
        connection: connectionTypes.TCP,
    },
    AUDAC_MTX: {
        value: 'AUDAC_MTX',
        label: 'Audac MTX',
        connection: connectionTypes.TCP,
    },
    AUDAC_M2: {
        value: 'AUDAC_M2',
        label: 'Audac M2',
        connection: connectionTypes.TCP,
    },
    AUDAC_LUNA_F: {
        value: 'AUDAC_LUNA_F',
        label: 'Audac Luna F',
        connection: connectionTypes.TCP,
    },
    DBXZONEPRO: {
        value: 'DBXZONEPRO',
        label: 'Dbx ZonePro',
        connection: connectionTypes.TCP,
    },
    BOSCH: {
        value: 'BOSCH',
        label: 'Bosch',
        connection: connectionTypes.TCP,
    },
    SYMETRIX_JUPITER: {
        value: 'SYMETRIX_JUPITER',
        label: 'Symetrix Jupiter',
        connection: connectionTypes.TCP,
    },
    ALLEN_HEATH: {
        value: 'ALLEN_HEATH',
        label: 'Allen & Heath',
        connection: connectionTypes.TCP,
    },
    KRAMER: {
        value: 'KRAMER',
        label: 'Kramer',
        connection: connectionTypes.TCP,
    },
    NONE: {
        value: 'NONE',
        label: 'None',
    }
};


export {
    processorTypes,
    connectionTypes
};