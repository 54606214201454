import { useState } from "react";
import styled from "styled-components";
import serverConnection from "../../../../scripts/server/ServerConnection";
import { ModalContainer, ModalHeader, ModalText, RowContainer } from "../../../panel/ModalStyles";
import { processorTypes } from "../../../settings/venue/device/AudioSystem/processorTypes";
import Button from "../../../UiComponents/Button";
import Checkbox from "../../../UiComponents/Checkbox";
import InnerCard from "../../../UiComponents/containers/InnerCard";
import InputField from "../../../UiComponents/InputField";
import Selector from "../../../UiComponents/Selector";
import { getHubDisplayName } from "../hub/hubUtils";
import { cellStates } from "../../table/tableTypes";
import ProcessorTypeChoiceField from "./fields/ProcessorTypeField";

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
`

const FlexRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const IpContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 0 !important;
`

const ResultCard = styled(InnerCard)`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 0 5px;
`

const StyledInputField = styled(InputField).attrs({ placeholder: 255, type: 'number', min: 0, max: 255 })`
    width: fill-available;
`

const AddProcessorButton = styled(Button)`
    grid-column: 2;
    grid-row: 1 / span 4;
`

const scanStates = {
    NONE: 0,
    SCANNING: 1,
    COMPLETED: 2,
    FAILED: 3
}

const ProcessorScan = props => {
    const [selectedHub, setSelectedHub] = useState(props?.hubs[0] ?? null);
    const [selectedProtocol, setSelectedProtocol] = useState(props.preSelectedProcessor?.type || '');
    const [scanOptionalSubnet, setScanOptionalSubnet] = useState(false);
    const [scanState, setScanState] = useState(scanStates.NONE);
    const [scanResults, setScanResults] = useState();
    const [subnet0, setSubnet0] = useState();
    const [subnet1, setSubnet1] = useState();
    const [subnet2, setSubnet2] = useState();

    const getSubnet = (ip) => {
        return ip?.split('.').slice(0, 3)?.join('.');
    }

    const scanForProcessors = async () => {
        try {
            setScanState(scanStates.SCANNING);
            let concatenatedSubnet = (scanOptionalSubnet === true) ? concatenateSubnet(subnet0, subnet1, subnet2) : null;
            const res = await serverConnection.hubEventHandler.sendEvent(
                selectedHub?.hubId,
                selectedHub?.customerId,
                'SCAN_FOR_DEVICES',
                { protocol: selectedProtocol, subnet: concatenatedSubnet },
                30000
            );
            setScanState(scanStates.COMPLETED);
            setScanResults(res);
        } catch (err) {
            setScanState(scanStates.FAILED);
        }
    }

    return <ModalContainer>
        {props.preSelectedProcessor ?
            <ModalHeader style={{fontSize: '1.2rem', marginTop: '10px'}}>Scan settings</ModalHeader>
        :
            <ModalHeader>Scan for processors</ModalHeader>
        }

        {props.hubs?.length > 1 ? <>
            <label>Selected hub</label>
            <Selector
                items={props.hubs?.map(hub => ({ display: getHubDisplayName(hub), key: hub.hubId }))}
                selectedItem={{ key: selectedHub.hubId }}
                onClick={(item) => setSelectedHub(props.hubs?.find(hub => hub.hubId === item.key))}
            />
        </> : <></>}

        {!props.preSelectedProcessor && <>
            <label>Processor type</label>
            <ProcessorTypeChoiceField
                type={selectedProtocol}
                setValue={(value) => setSelectedProtocol(value)}
                cellControl={{ cellState: cellStates.FORM }} />
        </>}

        <label>Hub network interfaces</label>
        {Object.keys(selectedHub?.networkConfig ?? {})?.map(key => {
            let networkInterface = selectedHub?.networkConfig?.[key];
            return <ModalText>{key}: {getSubnet(networkInterface?.address)}</ModalText>
        })}

        <FlexRow>
            <Checkbox checked={scanOptionalSubnet} onChange={() => setScanOptionalSubnet(prev => !prev)} />
            <label>Scan optional subnet</label>
        </FlexRow>

        {scanOptionalSubnet === true ? <>
            <IpContainer>
                <StyledInputField value={subnet0} onChange={(e) => setSubnet0(Number(e.target.value))} />
                <StyledInputField value={subnet1} onChange={(e) => setSubnet1(Number(e.target.value))} />
                <StyledInputField value={subnet2} onChange={(e) => setSubnet2(Number(e.target.value))} />
            </IpContainer>
            <ModalText>{(!isNaN(subnet0) && !isNaN(subnet1) && !isNaN(subnet2)) ? `${subnet0}.${subnet1}.${subnet2}` : 'Invalid subnet'}</ModalText>
        </> : <></>}

        {scanState != scanStates.SCANNING ? <>
            <RowContainer>
                <Button
                    onClick={() => scanForProcessors()}
                    primary = {props.preSelectedProcessor ? false : true}
                    tertiary = {props.preSelectedProcessor ? true : false}
                >Scan
                </Button>
            </RowContainer>
        </> : <ModalText>Scanning...</ModalText>}

        {scanState === scanStates.COMPLETED ? <>
            <label>Scan results: {scanResults?.length === 0 ? 'no processors found' : null}</label>
            {scanResults?.map(result => {
                return <ResultCard>
                    <label>{result.protocol}</label>
                    <ModalText>{result.ip}</ModalText>
                    <ModalText>{result.hostName}</ModalText>
                    <ModalText>{result.macAddress}</ModalText>
                    <AddProcessorButton primary small
                        onClick={() => props.selectProcessor({
                            ipAddress: result.ip,
                            type: result.protocol,
                            hubId: selectedHub.hubId,
                            name: props.preSelectedProcessor.name || processorTypes[result.protocol]?.label,
                        }, concatenateSubnet(subnet0, subnet1, subnet2))}>
                        Add
                    </AddProcessorButton>
                </ResultCard>
            })}
        </> : <></>}
    </ModalContainer>
}

export default ProcessorScan;

const concatenateSubnet = (subnet0, subnet1, subnet2) => {
    return (!isNaN(subnet0) && !isNaN(subnet1) && !isNaN(subnet2)) ? `${subnet0}.${subnet1}.${subnet2}` : null;
}