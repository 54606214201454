import { useState } from "react";
import styled from "styled-components";
import Modal from "../../../../UiComponents/Modal";
import { FieldButton } from "../../../fields/styling/FieldStyles";
import { ModalContainer } from "../../../../panel/ModalStyles";
import { readOnlyColor } from "../../../fields/Styles";
import VersionUpdate from "../../../../setup/VersionUpdate";

const FieldContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const VersionField = ({ hub }) => {
    const [showModal, setShowModal] = useState(false);
    
    return <>
        <FieldContainer>

            <div style={{color: readOnlyColor}}>{hub.programVersion}</div>
            {/* // Removed the More button so hub update is unavailble
            <div>
                <FieldButton tertiary small onClick={() => setShowModal(true)}>More</FieldButton>
            </div>
            */}
        </FieldContainer>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                Hub Version
                <div>
                <VersionUpdate hub={hub}/>
                </div>
            </ModalContainer>
        </Modal>
    </>
}

export default VersionField;
