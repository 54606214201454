import { addSource } from "../../../../scripts/source";

const addNewSource = async (props, processors, customerId) => {
    console.log('Adding new sourceSelector', props);
    try {
        const newSource = {
            ...props,
            customerId
        }
        await addSource(newSource);
    } catch (err) {
        console.log('Failed to add new sourceSelector', err);
    }
}

const getNextAddress = (objects, processors) => {
    try {
        if (processors.length === 1) {
            return objects.length ? Math.max(...objects.map(object => object.address)) + 1 : 1
        }
    } catch (err) {
        return null;
    }
}

export {
    addNewSource,
    getNextAddress
}
