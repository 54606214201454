import { useEffect, useRef } from "react";
import { useState } from "react";
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from "react-icons/fa";
import serverConnection from "../../scripts/server/ServerConnection";
import WiFiNetworks from "../settings/venue/device/NetworkConfig/WiFiNetworks";
import Button from "../UiComponents/Button";
import Radio from "../UiComponents/Radio";
import { TextMuted } from "../UiComponents/text/StyledText";
import { networkInterfaces } from "./networkInterfaces";
import setupSteps from "./setupSteps";
import { RowContainer, Header, SectionContainer, SetupContainer, ConnectionLabel, ErrorLabel, WarningLabel, GoodLabel } from "./setupStyles";
import VersionUpdate from "./VersionUpdate";

const networkConnectionStates = {
    NONE: 0,
    CONNECTING: 1,
    CONNECTED: 2,
    NOT_CONNECTED: 3
}

const NetworkSetup = props => {
    const [wifiConnectionState, setWifiConnectionState] = useState(networkConnectionStates.NONE);
    const [ethernetConnectionState, setEthernetConnectionState] = useState(networkConnectionStates.NONE);
    const [connectedToNetwork, setConnectedToNetwork] = useState(false);
    const [connectedToPreferredNetwork, setConnectedToPreferredNetwork] = useState(false);
    const [showAvailableWiFiNetworks, setShowAvailableWiFiNetworks] = useState(false);
    const ethernetTimeout = useRef(null);
    const wifiTimeout = useRef(null);

    useEffect(() => {
        setEthernetConnectionState(networkConnectionStates.CONNECTING);
        setWifiConnectionState(networkConnectionStates.CONNECTING);

        checkEthernetConnectionState();
        checkWiFiConnectionState();

        return () => {
            clearTimeout(ethernetTimeout.current);
            clearTimeout(wifiTimeout.current);
        }
    }, [])

    useEffect(() => {
        setConnectedToNetwork(wifiConnectionState === networkConnectionStates.CONNECTED ||
            ethernetConnectionState === networkConnectionStates.CONNECTED);

        setConnectedToPreferredNetwork((wifiConnectionState === networkConnectionStates.CONNECTED && props.networkInterface === networkInterfaces.WIFI.key) ||
            (ethernetConnectionState === networkConnectionStates.CONNECTED && props.networkInterface === networkInterfaces.ETHERNET.key))

    }, [wifiConnectionState, ethernetConnectionState, props.networkInterface])

    const checkEthernetConnectionState = async () => {
        try {
            const connectionState = await serverConnection.hubEventHandler.sendEvent(
                props.hubId,
                null,
                'CHECK_INTERNET_CONNECTION_STATE',
                { interfaceName: networkInterfaces.ETHERNET.interfaceName, preventEventLog: true },
                3000,
                true
            );
            setEthernetConnectionState(connectionState?.connected === true ? networkConnectionStates.CONNECTED : networkConnectionStates.NOT_CONNECTED);
        } catch (error) {
            setEthernetConnectionState(networkConnectionStates.NOT_CONNECTED);
        }

        startEthernetTimeout();
    }

    const checkWiFiConnectionState = async () => {
        try {
            const connectionState = await serverConnection.hubEventHandler.sendEvent(
                props.hubId,
                null,
                'CHECK_INTERNET_CONNECTION_STATE',
                { interfaceName: networkInterfaces.WIFI.interfaceName, preventEventLog: true },
                3000,
                true
            );
            setWifiConnectionState(connectionState?.connected === true ? networkConnectionStates.CONNECTED : networkConnectionStates.NOT_CONNECTED);
        } catch (error) {
            setWifiConnectionState(networkConnectionStates.NOT_CONNECTED);
        }

        startWiFiTimeout();
    }

    const startEthernetTimeout = () => {
        ethernetTimeout.current = setTimeout(async () => {
            await checkEthernetConnectionState();
        }, 2000);
    }

    const startWiFiTimeout = () => {
        wifiTimeout.current = setTimeout(async () => {
            await checkWiFiConnectionState();
        }, 2000);
    }

    const handleConnecting = () => {
        clearTimeout(wifiTimeout.current);
        clearTimeout(ethernetTimeout.current);
    }

    const handleConnect = () => {
        setWifiConnectionState(networkConnectionStates.CONNECTED);
        setTimeout(() => {
            startWiFiTimeout();
        }, 2000);
        setTimeout(() => {
            startEthernetTimeout();
        }, 10000);
    }

    const handleDisconnect = () => {
        setWifiConnectionState(networkConnectionStates.NOT_CONNECTED);
    }

    return <SetupContainer>
        <Header>Connecting to internet</Header>

        <SectionContainer>
            <label>Choose preferred network interface for hub setup.</label>
        </SectionContainer>

        <SectionContainer>
            <RowContainer>
                <Radio
                    name={"selectedInterface"}
                    checked={props.networkInterface === networkInterfaces.ETHERNET.key}
                    onChange={() => props.setNetworkInterface(networkInterfaces.ETHERNET.key)}
                />
                <TextMuted>Ethernet</TextMuted>
                <ConnectionLabel
                    connected={ethernetConnectionState === networkConnectionStates.CONNECTED}>
                    {ethernetConnectionState === networkConnectionStates.CONNECTED ?
                        <><FaCheckCircle /> Connected</>
                        : <><FaTimesCircle /> Not connected</>}
                </ConnectionLabel>
            </RowContainer>
        </SectionContainer>

        <SectionContainer>
            <RowContainer>
                <Radio
                    name={"selectedInterface"}
                    checked={props.networkInterface === networkInterfaces.WIFI.key}
                    onChange={() => props.setNetworkInterface(networkInterfaces.WIFI.key)}
                />
                <TextMuted>Wi-Fi</TextMuted>
                <ConnectionLabel
                    connected={wifiConnectionState === networkConnectionStates.CONNECTED}>
                    {wifiConnectionState === networkConnectionStates.CONNECTED ?
                        <>
                            <FaCheckCircle /> Connected</>
                        : <>
                            <FaTimesCircle /> Not connected</>}
                </ConnectionLabel>
            </RowContainer>
        </SectionContainer>

        <div>
            <Button
                small
                tertiary
                disabled={props.networkInterface === networkInterfaces.WIFI.key}
                onClick={() => setShowAvailableWiFiNetworks(prev => !prev)}>
                {showAvailableWiFiNetworks || props.networkInterface === networkInterfaces.WIFI.key ? 'Hide' : 'Show'} available Wi-Fi networks
            </Button>
        </div>

        {props.networkInterface === networkInterfaces.WIFI.key || showAvailableWiFiNetworks === true ? <>
            <SectionContainer>
                <label>Available Wi-Fi networks</label>
                <WiFiNetworks
                    hubId={props.hubId}
                    onConnecting={() => handleConnecting()}
                    onConnect={() => handleConnect()}
                    onDisconnect={() => handleDisconnect()}
                />
            </SectionContainer>
        </> : <></>}
        
        {/* Removed the firmware version update availability from local setup as it does not handle hub update with new npm packages
        {connectedToNetwork ? <>
            <SectionContainer>
                <label>Firmware Version</label>
                <VersionUpdate hubId={props.hubId} />
            </SectionContainer>
        </> : <></>}
        */}

        <SectionContainer>
            <label>Next step</label>

            {!connectedToNetwork ? <>
                <ErrorLabel><FaExclamationCircle /> Hub is not connected to the internet.</ErrorLabel>
            </> : !connectedToPreferredNetwork ? <>
                <WarningLabel><FaExclamationCircle /> Preferred network interface is not connected.</WarningLabel>
            </> : <>
                <GoodLabel><FaCheckCircle /> Network requirements satisfied.</GoodLabel>
            </>}

            <div>
                <Button
                    small
                    primary={connectedToNetwork && connectedToPreferredNetwork}
                    tertiary={!connectedToNetwork || !connectedToPreferredNetwork}
                    onClick={() => props.setSetupStep(setupSteps.CUSTOMER_SETUP)}>
                    {connectedToNetwork && connectedToPreferredNetwork ? 'Continue' : 'Continue anyway'}
                </Button>
            </div>
        </SectionContainer>

    </SetupContainer>
}

export default NetworkSetup;