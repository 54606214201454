import { deleteSourceMatrix, updateSourceMatrixes } from "../../../../scripts/sourceMatrix";
import NameField from "../../fields/NameField";
import NumberField from "../../fields/NumberField";
import ProcessorChoiceField from "../../fields/ProcessorChoiceField";
import StringField from "../../fields/StringField";
import { DisplayField } from "../../fields/Styles";
import { SourceMatrixIcon } from "../../styling/Icons";
import { getDefaultId } from "../../table/tableUtils";
import SourceMatrixTypeChoiceField from "./fields/SourceMatrixTypeChoiceField";
import { addNewSourceMatrix } from "./sourceMatrixUtils";
import { Placeholder } from "../../table/styles/TableStyles";
import AddObjectButton from "../../table/AddObjectButton";
import Button from "../../../UiComponents/Button";
import { installationTabs } from "../../installationTypes";

const idField = 'sourceMatrixId';

export const getSourceMatrixTableProps = ({ sourceMatrixes, processors, customerId, setSelectedTab }) => {

    const getColumns = (sourceMatrixes) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        formValidate: (sourceMatrix) => {
            return sourceMatrix.name ? null : { type: 'ERROR', message: 'Name is required' };
        },
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <NameField
                object={sourceMatrix}
                updateObject={updateSourceMatrix}
                objects={sourceMatrixes}
                idField={'sourceMatrixId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        formValidate: (sourceMatrix) => {
            return sourceMatrix.processorId ? null : { type: 'ERROR', message: 'Processor is required' };
        },
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <ProcessorChoiceField
                object={sourceMatrix}
                processors={processors}
                updateObject={updateSourceMatrix}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'type',
        displayName: 'Type',
        width: '100px',
        form: true,
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <SourceMatrixTypeChoiceField
                type={sourceMatrix.type}
                setValue={(value) => updateSourceMatrix({ type: value })}
                cellControl={cellControl}
            />
        )
    },{
        key: 'address',
        displayName: 'Address',
        width: '100px',
        form: true,
        formValidate: (sourceMatrix) => {
            return sourceMatrix.address ? null : { type: 'ERROR', message: 'Address is required' };
        },
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <StringField
                value={sourceMatrix.address}
                setValue={(value) => updateSourceMatrix({ address: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'nInputs',
        displayName: 'Inputs',
        width: '100px',
        form: true,
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <NumberField
                value={sourceMatrix.nInputs}
                setValue={(value) => updateSourceMatrix({ nInputs: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'nOutputs',
        displayName: 'Outputs',
        width: '100px',
        form: true,
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <NumberField
                value={sourceMatrix.nOutputs}
                setValue={(value) => updateSourceMatrix({ nOutputs: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'inputNumberOffset',
        displayName: 'Input number offset',
        width: '100px',
        form: true,
        render: (sourceMatrix, updateSourceMatrix, cellControl) => (
            <NumberField
                value={sourceMatrix.inputNumberOffset === undefined ? 0 : sourceMatrix.inputNumberOffset}
                setValue={(value) => updateSourceMatrix({ inputNumberOffset: value })}
                cellState={cellControl.cellState}
            />
        )
    }];

    const saveObjects = async (changedSourceMatrixes) => {
        try {
            await updateSourceMatrixes(changedSourceMatrixes);
        } catch (err) {
            console.log('Failed to update sourceMatrixes');
        }
    }

    const deleteObject = async (sourceMatrix) => {
        try {
            await deleteSourceMatrix(sourceMatrix.sourceMatrixId);
        } catch (err) {
            console.log('Error failed to delete sourceMatrix');
        }
    }

    const addObject = processors.length ? (newSourceMatrix) => addNewSourceMatrix(newSourceMatrix, processors, customerId) : null;

    const getListItemProps = (sourceMatrix) => {
        return {
            displayName: sourceMatrix.name,
            Icon: SourceMatrixIcon
        }
    }

    const getPlaceholder = () => {
        return (
            <Placeholder>
                <p>No source matrixes added.</p>
                <AddObjectButton
                    title='add matrix' 
                    objects={sourceMatrixes}
                    onSubmit={addObject}
                    columns={getColumns(sourceMatrixes)}
                    overrideButtonStyle={{
                        title: 'Add source matrix',
                    }}
                />
            </Placeholder>
        )
    }

    const getActions = (sourceMatrix) => [];

    return {
        title: 'Source Matrixes',
        getColumns,
        parentObjects: sourceMatrixes,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
