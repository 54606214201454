import React from 'react';
import AddressField from '../../../fields/AddressField';
import { getProtocol } from '../../protocol/protocolUtils';


const ObjectAddressField = ({ zone, updateZone, zones, processors, cellState, placeholder }) => {
    const processor = processors.find(processor => processor.processorId === zone.processorId); 
    const Protocol = getProtocol(processor?.type);

    const validate = (address) => {
        const addressesOnSameProcessor = zones.filter(z => z.zoneId !== zone.zoneId && z.processorId === zone.processorId).map(z => z.objectAddress?.toLowerCase());
        if (address && addressesOnSameProcessor.includes(address?.toLowerCase())) {
            return ({type: 'WARNING', message: 'Multiple zones with same address on same processor'});
        } else {
            return Protocol.validateObjectAddress(address);
        }
    }

    return <AddressField
        processorId={zone.processorId}
        hubId={zone.hubId || processor?.hubId}
        type={'VOLUME'}
        address={zone.objectAddress}
        customerId={zone.customerId || processor?.customerId}
        setAddress={(address) => updateZone({ objectAddress: address })}
        error={validate(zone.objectAddress)}
        cellState={cellState}
        placeholder={placeholder}
    />
}

export default ObjectAddressField;
