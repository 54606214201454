import React, { useEffect, useState } from 'react';
import StringField from './StringField';
import { cellStates } from '../table/tableTypes';
import serverConnection from '../../../scripts/server/ServerConnection';
import { FieldButton, MainContainer, ResultContainer, ResultValue } from './styling/FieldStyles';
import FieldTestResult from './components/FieldTestResult';
import { testStates } from '../installationTypes';

const AddressField = props => {
    const [state, setState] = useState(testStates.NONE);
    const [testResult, setTestResult] = useState();
    const [testPassed, setTestPassed] = useState(true);

    const automaticAddressTest = async () => {
        if (props.address === '' || props.address === 'N/A' || !props.address) return null;
        try {
            const res = await runAddressTest();
            if (res.status === 'OK' && res.value !== null) return true;
            else return false;
        } catch (err) {
            return false;
        }
    };

    const runAutomaticTests = async () => {
        var success = await automaticAddressTest();
        if (success === true) {
            setTestPassed(testStates.SUCCESS);
        } else if (success === false) {
            setTimeout(async () => {
                var retry = await automaticAddressTest();
                if (retry === true) setTestPassed(testStates.SUCCESS);
                else setTestPassed(testStates.FAILED);
            }, 2000);
        }
    }


    useEffect(() => {
        //runAutomaticTests();
    }, [props.address]);

    const testAddress = async () => {
        setTestResult(null);
        setState(testStates.TESTING);
        try {            
            const res = await runAddressTest();
            if (res.status === 'OK') {
                setState(testStates.SUCCESS);
                setTestResult(res.value);
                if (res.value !== null) setTestPassed(true);
            } else {
                setState(testStates.FAILED);
            }
        } catch (err) {
            setState(testStates.FAILED);
            setTestResult(err);
        }
    }

    const runAddressTest = async () => {
        const testData = {
            processorId: props.processorId,
            type: props.type,
            address: props.address,
            customerId: props.customerId,
            hubId: props.hubId
        }
        const res = await serverConnection.hubEventHandler.sendEvent(
            props.hubId,
            props.customerId,
            'PROCESSOR_ADDRESS_TEST',
            testData
        );
        return res;
    };

    const onAddressChange = (value) => {
        if (state !== testStates.NONE) {
            setState(testStates.NONE);
            setTestResult(null);
        }
        props.setAddress(value);
    }
    return <>
        <MainContainer>
            <StringField
                value={props.address}
                setValue={(value) => onAddressChange(value)}
                error={props.error}
                disabled={props.disabled}
                cellState={props.cellState}
                placeholder={props.placeholder}
            />

            {!props.disabled ? <>
                {state !== testStates.TESTING && props.address !== '' && props.address !== 'N/A' && props.address ? <>
                    <FieldButton autoTest={testPassed} tertiary small onClick={testAddress}>Test</FieldButton>
                </> : <></>}
            </> : <></>}
        </MainContainer>

        <FieldTestResult  disabled={props.disabled} state={state} testResult={testResult} close={() => setState(testStates.NONE)}/>
    </>
}

export default AddressField;
