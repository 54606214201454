import React from "react";
import Button from "../../../UiComponents/Button";
import { fetchSpeakers } from "./speakerUtils";
import FetchSpeakerAnimation from "./FetchSpeakerAnimation";
import ShimmerPrealoaderButton from "./ShimmerPrealoaderButton";
import { Placeholder } from "../../table/styles/TableStyles";
import { Add } from "@material-ui/icons";
import AddSpeakersModal from "./AddSpeakersModal";

const SpeakerPlaceholder = ({customerId, hubs, processors, speakers}) => {

    const [scanning, setScanning] = React.useState(false);
    const [scanAttempted, setScanAttempted] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);

    function scanCallback() {
        setScanAttempted(true);
        setScanning(true);
        setTimeout(() => {
            setScanning(false);
        }, [10000]);        
    }


    return (
        <Placeholder>
            {processors.length ? <>
                {scanning && !speakers.length? <FetchSpeakerAnimation/> :<>
                    {(scanAttempted && !speakers.length)?
                        <p>No speakers found. <br/>Please make sure the speakers are connected to the network same network as the hub and try again.</p>
                    :
                        <p>No speakers added yet.</p>
                    }
                    <Button primary small onClick={() => setShowModal(true)}>Add speakers</Button>
                </>}
            </>:
                <p>Start by adding a speaker compatible processor</p>
            }
        <AddSpeakersModal
            show={showModal}
            close={() => {setShowModal(false)}}
            customerId={customerId}
            hubs={hubs}
            processors={processors}
            scanCallback={scanCallback}
        />
        </Placeholder>
    )
}

export default SpeakerPlaceholder;