import { useEffect, useState } from "react";
import styled from "styled-components";
import { validateIPaddress } from "../../../../settings/venue/device/NetworkConfig/networkUtils";
import Button from "../../../../UiComponents/Button";
import { updateHub } from "../../../../../scripts/hub";
import InnerCard from "../../../../UiComponents/containers/InnerCard";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import theme from "../../../../../UI/theme";
import { getVirtualInterfaceIpFromProcessorIp } from "../../hub/hubUtils";

const Container = styled.div`
    margin: 8px 0;
`
const DetailsContainer = styled(InnerCard)`
    background-color: ${theme.colors.dropdownGray};

    >div {
        margin: 8px 0;
    }

    button {
        margin-right: 6px;
    }
`

const VirtualInterfaceField = ({ processor, hub }) => {
    const [showDetailed, setShowDetailed] = useState(false);
    const [virtualInterfaceAddress, setVirtualInterfaceAddress] = useState();

    useEffect(() => {
        onAddressUpdate()
    }, [processor?.ipAddress]);

    const onAddressUpdate = () => {
        if (validateIPaddress(processor.ipAddress)) {
            const virtualIp = getVirtualInterfaceIpFromProcessorIp(processor.ipAddress);
            setVirtualInterfaceAddress(virtualIp);
        }
    }

    const addVirtualInterface = async () => {
        try {
            const updatedNetworkConfig = { ...hub.networkConfig };
            updatedNetworkConfig['eth0:0'] = {
                address: virtualInterfaceAddress,
                isStatic: true
            };
            await updateHub({ hubId: hub.hubId, networkConfig: updatedNetworkConfig })
        } catch (err) {
            console.log('Failed to add virtual interface', err);
        }
    }

    return <>
        {validateIPaddress(processor.ipAddress) ? <>
            <Container>
                {showDetailed ? <>
                    <DetailsContainer style={{ margin: '8px 0px' }}>
                        <label>Virtual interface address</label><br />
                        <div>{virtualInterfaceAddress} (eth0:0)</div>

                        <Button primary small onClick={() => addVirtualInterface()}>Add</Button>
                        <Button secondary small onClick={() => setShowDetailed(false)}>Cancel</Button>
                    </DetailsContainer>
                </> : <>
                    <TextMuted>No hubs on this subnet: </TextMuted>
                    <Button primary small onClick={() => setShowDetailed(true)}>Add virtual interface</Button>
                </>}
            </Container>
        </> : <></>}
    </>
}

export default VirtualInterfaceField;