import React, { useEffect, useState } from 'react';
import Modal from '../../../UiComponents/Modal';
import Button from '../../../UiComponents/Button';
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText} from '../../../panel/ModalStyles';
import { fetchSpeakers } from './speakerUtils';

const AddSpeakersModal = ({ show=false, close=false, customerId, hubs, processors, scanCallback=false, setShowActionPromptsHandler=false}) => {
    const [showModal, setShowModal] = useState(show);

    useEffect(() => setShowModal(show) , [show])

    function scanForSpeakers() {
        if (scanCallback) scanCallback();
        console.log('scanForSpeakers');
        fetchSpeakers(customerId, hubs, processors);
        setShowModal(false);
        if (setShowActionPromptsHandler) setShowActionPromptsHandler(true, 6);
        if (close) close();
    }

    const closeHandler= () => {
        if (setShowModal) {
            setShowModal(false);
            if (close) close();
        }
    }

    return (
        <Modal show={showModal} close={ closeHandler } >
            <ModalContainer>
                <ModalHeader>Add Speakers</ModalHeader>
                <ModalText>This action will search your network for IP speakers.</ModalText>
                <ButtonRowContainer>
                    <Button primary disabled={false} onClick={scanForSpeakers}>Add Speakers</Button>
                    <Button secondary onClick={ closeHandler }>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    )
}


export default AddSpeakersModal;