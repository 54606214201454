import { deleteProcessor, updateProcessors } from '../../../../scripts/processor';
import ReconnectProcessorButton from '../../../settings/venue/device/AudioSystem/ReconnectProcessorButton';
import HubChoiceField from '../../fields/HubChoiceField';
import NameField from '../../fields/NameField';
import { ProcessorIcon } from '../../styling/Icons';
import { getDefaultId, hideInList } from '../../table/tableUtils';
import { getProcessorDisplayStatus, getProcessorStatus, getProcessorStatusColor } from '../../utils/connectionStatus';
import { actionTypes } from '../../utils/installationTypes';
import InterfaceChoiceField, { hideInterfaceField, disableInterfaceField } from './fields/InterfaceChoiceField';
import IpAddressField, { disableIpAddressField, hideIpAddressField, validateIpAddressField } from './fields/IpAddressField';
import { addNewProcessor } from './processorUtils';
import ProcessorError from './ProcessorError';
import ProcessorScanButton from './actions/ProcessorScanButton';
import StatusField from '../../fields/StatusField';
import ProcessorTypeField from './fields/ProcessorTypeField';
import heatmapTypes from '../../../heatmap/container/heatmapTypes';
import InstallationInfoButton from '../../fields/components/InstallationInfoButton';
import { Placeholder } from '../../table/styles/TableStyles';
import AddObjectButton from '../../table/AddObjectButton';
import { render } from '@testing-library/react';
import ProcessorScanForm from './actions/ProcessorScanForm';

const idField = 'processorId';

export const getProcessorTableProps = ({ processors, hubs, customerId, serverStatus, displayMode }) => {

    const getColumns = (processors) => [{
        displayName: 'Name',
        key: 'name',
        form: true,
        formValidate: (processor) => {
            return processor.name ? null : { type: 'ERROR', message: 'Name is required' };
        },
        render: (processor, updateProcessor, cellControl) => (
            <NameField
                object={processor}
                updateObject={updateProcessor}
                objects={processors}
                idField={idField}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        displayName: 'Status',
        key: 'status',
        hide: hideInList(displayMode),
        render: (hub) => {
            const listItemProps = getListItemProps(hub)
            return <StatusField
                displayStatus={listItemProps.displayStatus}
                statusColor={listItemProps.statusColor}
                error={listItemProps.error}
            />
        }
    }, {
        displayName: 'Hub',
        key: 'hubId',
        form: true,
        hideInItem: () => hubs.length < 2,
        hide: hubs.length < 2,
        getSuggested: () => getDefaultId(hubs, 'hubId'),
        render: (processor, updateProcessor, cellControl) => (
            <HubChoiceField
                hubId={processor.hubId}
                hubs={hubs}
                updateObject={updateProcessor}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'Type',
        key: 'type',
        form: true,
        formValidate: (processor) => {
            console.log('processor', processor);
            return processor.type ? null : { type: 'ERROR', message: 'Type is required' };
        },
        render: (processor, updateProcessor, cellControl) => (
            <ProcessorTypeField
                type={processor.type}
                setValue={(type) => updateProcessor({ type })}
                cellControl={cellControl}
            />
        )
    }, {
        displayName: 'IP Address',
        key: 'ipAddress',
        form: true,
        hideInItem: (processor) => disableIpAddressField(processor),
        hide: hideIpAddressField(processors),
        formValidate: (processor) => validateIpAddressField(
            processor,
            hubs.find(hub => hub.hubId === processor.hubId)
        ),
        render: (processor, updateProcessor, cellControl) => (
            <IpAddressField
                processor={processor}
                updateObject={updateProcessor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
                cellControl={cellControl}
            />
        )
    }, {
        form: true,
        hide: true,
        render: (processor) => {
            return( 
                <ProcessorScanForm
                    tertiary
                    hubs={hubs}
                    columns={getColumns(processors)}
                    onSubmit={addObject}
                    objects={processors}
                    preSelectedProcessor={processor}
                >Scan
                </ProcessorScanForm>
        )}
    },{
        displayName: 'Interface',
        key: 'interface',
        form: true,
        hideInItem: (processor) => disableInterfaceField(processor, hubs.find(hub => hub.hubId === processor.hubId)),
        hide: hideInterfaceField(processors, hubs),
        render: (processor, updateProcessor, cellControl) => (
            <InterfaceChoiceField
                processor={processor}
                updateObject={updateProcessor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
                cellControl={cellControl}
            />
        )
    },{
        displayName: 'Reconnect',
        key: 'reconnect',
        render: (processor) => (
            <ReconnectProcessorButton
                processor={processor}
                hub={hubs.find(hub => hub.hubId === processor.hubId)}
            />
        )
    }, {
        key: 'installationDocumentation',
        displayName: 'Doc',
        width: '40px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (processor) => (
            <InstallationInfoButton
                customerId={customerId}
                deviceId={processor.processorId}
                deviceType={heatmapTypes.elementTypes.PROCESSOR}
                name={processor.name}
            />
        )
    }];

    const getActions = (processor) => [{
        key: 'processorScan',
        type: actionTypes.LIST,
        render: () => (
            <ProcessorScanButton
                secondary
                hubs={hubs}
                columns={getColumns(processors)}
                onSubmit={addObject}
                objects={processors}
            />
        )
    }, {
        key: 'installationinfo',
        type: actionTypes.PRIMARY,
        render: () => (
            <InstallationInfoButton
                customerId={customerId}
                deviceId={processor.processorId}
                deviceType={heatmapTypes.elementTypes.PROCESSOR}
                name={processor.name}
            />
        )
    }]

    const getListItemProps = (processor) => {
        const hub = hubs.find(hub => hub.hubId === processor.hubId);
        const processorStatus = getProcessorStatus(processor, hub, serverStatus)
        return {
            displayName: processor.name,
            displayStatus: getProcessorDisplayStatus(processorStatus),
            statusColor: getProcessorStatusColor(processorStatus),
            error: processor.error && <ProcessorError error={processor.error} />,
            Icon: ProcessorIcon
        }
    }

    async function saveObjects(changedProcessors) {
        try {
            await updateProcessors(changedProcessors);
        } catch (err) {
            console.log('save error', err);
        }
    }

    const deleteObject = async (processor) => {
        try {
            await deleteProcessor(processor.processorId);
        } catch (err) {
            console.log('Error failed to delete zone');
        }
    }

    const getPlaceholder = () => {
        return <Placeholder>
            {hubs.length ? <>
                <p>No processors have been added.</p>
                <AddObjectButton
                    title='Processors' 
                    objects={processors}
                    onSubmit={addObject}
                    columns={getColumns(processors)}
                    overrideButtonStyle={{
                        title: 'Add Processor',
                    }}
                />
            </> : <>
                <p>Connect a hub before adding processors</p>
            </>}
        </Placeholder>
    }

    const addObject = hubs.length ? (newProcessor, submitProps) => addNewProcessor(newProcessor, customerId, submitProps) : null;

    return {
        title: 'Processors',
        getColumns,
        parentObjects: processors,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}