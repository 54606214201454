import { SensorIcon, SpeakersIcon } from "../UiComponents/Icons"
import { CalibrationIcon, DocumentationIcon, HubIcon, MeterIcon, ProcessorIcon, SourceIcon, SourceMatrixIcon, SourceSelectorIcon, ZoneIcon } from "./styling/Icons"


export const installationTabs = [
    { key: 'HUB', display: 'Hubs', icon: HubIcon },
    { key: 'PROCESSOR', display: 'Processors', icon: ProcessorIcon },
    { key: 'SOURCE', display: 'Sources', icon: SourceIcon },
    { key: 'SOURCE_MATRIX', display: 'Source Matrixes', icon: SourceMatrixIcon },
    { key: 'SOURCE_SELECTOR', display: 'Source Selectors', icon: SourceSelectorIcon },
    { key: 'ZONE', display: 'Zones', icon: ZoneIcon },
    { key: 'SPEAKER', display: 'Speakers', icon: SpeakersIcon},
    { key: 'METER', display: 'Meters', icon: MeterIcon },
    { key: 'SENSOR', display: 'Sensors', icon: SensorIcon },
    { key: 'DOCUMENTATION', display: 'Documentation', icon: DocumentationIcon },
    { key: 'CALIBRATION', display: 'Calibration', icon: CalibrationIcon }
]

export const testStates = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    FAILED: 3
}

export const loadingStates = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    FAILED: 3
}