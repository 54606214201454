import { useState } from "react";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import theme from "../../../../../UI/theme";
import NeutralSpinner from "../../../../UiComponents/Animations/NeutralSpinner";
import Button from "../../../../UiComponents/Button";
import { cellStates } from "../../../table/tableTypes";
import ZigbeeChannelChoiceField from "../fields/ZigbeeChannelChoiceField";
import { hasZigbeeNetwork } from "../hubUtils";
import { ButtonRowContainer, ModalText } from "../../../../panel/ModalStyles";

const formStates = {
    NONE: 0,
    FORMING: 1,
    SUCCESS: 2,
    FAILED: 3
}

const FormNetwork = ({ hub, allHubs, cancel, bestChannel }) => {
    const [state, setState] = useState(formStates.NONE);
    const [selectedChannel, setSelectedChannel] = useState(parseInt(bestChannel) || hub.zigbeeChannel);

    const formNetwork = async () => {
        try {
            setState(formStates.FORMING);
            const result = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'ZIGBEE_SETUP_START',
                { channel: selectedChannel }
            );
            if (result.status === 'OK') {
                setState(formStates.SUCCESS);
            } else {
                setState(formStates.FAILED);
            }
        } catch (err) {
            console.log('Form network error', err)
            setState(formStates.FAILED);
        }
    }

    return <>
        <h3>Form network</h3>
        {state === formStates.NONE ? <>
            <ModalText style={{marginBottom: '20px'}}>
                <span style={{color: theme.colors.mellowYellow}}>Caution:</span><br/>
                Changing channel will close the current network and disconnect all connected sensors.<br/>
                Channel recommendations are spessiffic to each hub.
            </ModalText>

            <label>Select Zigbee Channel</label>
            <ZigbeeChannelChoiceField
                hub={hub}
                allHubs={allHubs}
                zigbeeChannel={selectedChannel}
                setValue={(value) => setSelectedChannel(value)}
                cellControl={{ cellState: cellStates.FORM }}
            />

            <ButtonRowContainer>
                <Button primary onClick={formNetwork}>Form network</Button>
                <Button secondary onClick={cancel}>Cancel</Button>
            </ButtonRowContainer>
        </> : <></>}

        {state === formStates.FORMING ? <>
            <ModalText>Forming network on channel {selectedChannel}...</ModalText>
            <NeutralSpinner
                color={theme.colors.whiteSpace}
                size={60}
                speed={1.5}
            />
        </> : <></>}

        {state === formStates.SUCCESS ? <>
            <ModalText>Network was formed successfully on channel {selectedChannel}</ModalText>
            <ButtonRowContainer>
                <Button primary margin onClick={cancel}>Continue</Button>
            </ButtonRowContainer>
        </> : <></>}

        {state === formStates.FAILED ? <>
            <ModalText>Network forming failed on channel {selectedChannel}</ModalText>
            <ButtonRowContainer>
                <Button primary margin onClick={() => setState(formStates.NONE)}>Retry</Button>
                <Button secondary margin onClick={cancel}>Cancel</Button>
            </ButtonRowContainer>
        </> : <></>}
    </>
}

export default FormNetwork;

export const hideFormNetworkField = (hubs) => {
    return hubs?.find(hub => !hasZigbeeNetwork(hub)) === undefined;
}