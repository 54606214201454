import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const MainContentContainer = styled.div`
    cursor: default;

    :hover {
        background-color: ${theme.colors.grayHover};
    }
`

const PopupContainer = styled.div`
    position: absolute;
    left: -125px;
    bottom: 125%;
    z-index: 200;
    padding: 8px;
    background-color: ${theme.colors.darkSpace80};
    border-radius: 4px;
`

const PopupOverlay = styled.div`
    background-color: transparent;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
`

const popupStates = {
    NONE: 1,
    SHOW: 2,
    SHOW_FIXED: 3
}
const PopupElement = props => {
    const [showDetails, setShowDetails] = useState(popupStates.NONE);

    const clickHandler = () => {
        if (showDetails === popupStates.SHOW_FIXED) {
            setShowDetails(popupStates.NONE);
        } else {
            setShowDetails(popupStates.SHOW_FIXED);
        }
    }

    const onMouseOver = () => {
        if (showDetails === popupStates.NONE) {
            setShowDetails(popupStates.SHOW);
        }
    }

    const onMouseLeave = () => {
        if (showDetails === popupStates.SHOW) {
            setShowDetails(popupStates.NONE);
        }
    }

    return <div>
        <MainContentContainer onClick={clickHandler} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
            {props.mainContent}
        </MainContentContainer>
        {showDetails != popupStates.NONE && props.popupContent ? <>
            <PopupContainer>
                {props.popupContent}
            </PopupContainer>
            {showDetails == popupStates.SHOW_FIXED ? <>
                <PopupOverlay onClick={() => setShowDetails(popupStates.NONE)} />
            </> : <></>}
        </> : <></>}
    </div>
}

export default PopupElement;