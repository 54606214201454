import { displayModes } from "../utils/installationTypes"
import styled, { css } from 'styled-components';
import theme from "../../../UI/theme";
import { FaList, FaTable } from "react-icons/fa";
import { ListActionButton } from "./styles/ActionStyles";

const StyledButton = styled(ListActionButton)`    
    
    &:first-child {
        border-radius: 2px 0px 0px 2px;
    }
    &:last-child {
        border-radius: 0px 2px 2px 0px;
    }
    
    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const Container = styled.div`
    display: flex;
    ${props => props.fitContainer && css`
        width: 100%;
        >button {
            width: 100%; /* if both buttons unconditionally visible this must be 50% */
        }
    `}
`

const DisplayModeSelector = ({ displayMode, setDisplayMode, fitContainer }) => {

    return <Container fitContainer>

        { displayMode === displayModes.LIST &&
            <StyledButton small={!fitContainer} tertiary active={displayMode === displayModes.TABLE} onClick={() => setDisplayMode(displayModes.TABLE)}>
                <FaTable />
                {/* <label>Table</label> */}
            </StyledButton>
        }   

        { displayMode === displayModes.TABLE &&
            <StyledButton small={!fitContainer} tertiary active={displayMode === displayModes.LIST} onClick={() => setDisplayMode(displayModes.LIST)}>
                <FaList />
                {/* <label>List</label> */}
            </StyledButton>
        }
    </Container>
}

export default DisplayModeSelector;